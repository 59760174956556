<template>
	<div>
		<div class="pb-6 sm:hidden">
			<label
				for="tabs"
				class="sr-only"
			>
				<!-- TODO i18n -->
				Select a tab
			</label>
			<!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
			<select
				id="tabs"
				name="tabs"
				class="block w-full py-2 pl-3 overflow-auto text-base bg-white border-2 rounded-md dark:text-mx-gray-300 focus:outline-none focus:ring-0 border-mx-gray-100 dark:bg-mx-green-700 dark:shadow-white/5 dark:border-mx-green-600 sm:text-sm"
				@change="handleMobileNavigation"
			>
				<!-- TODO i18n -->
				<option
					v-for="tab in tabs"
					:key="tab.name"
					:selected="isActiveRoute(tab)"
					:value="tab.href"
					class="shadow-lg"
				>
					{{ tab.name }}
				</option>
			</select>
		</div>
		<div class="hidden sm:block">
			<div class="">
				<nav
					class="flex -mb-px space-x-8"
					aria-label="Tabs"
				>
					<template v-if="staticPage">
						<button
							v-for="tab in tabs"
							:key="tab.name"
							:class="[
								isTabActive(tab) ? 'border-mx-orange text-mx-orange font-bold' : 'border-transparent text-mx-gray-500 dark:text-mx-gray-50 hover:border-mx-gray-300 hover:text-mx-green-700',
								'whitespace-nowrap border-b-4 pt-4 pb-2 px-1 text-sm font-medium'
							]"
							@click="handleStaticNavigation(tab)"
						>
							{{ tab.name }}
						</button>
					</template>
					<template v-else>
						<NuxtLink
							v-for="tab in tabs"
							:key="tab.name"
							:to="tab.href"
							:class="[
								isActiveRoute(tab) ? 'border-mx-orange text-mx-orange font-bold' : 'border-transparent text-mx-gray-500 dark:text-mx-gray-50 hover:border-mx-gray-300 hover:text-mx-green-700',
								'whitespace-nowrap border-b-4 pt-4 pb-2 px-1 text-sm font-medium'
							]"
							:aria-current="isActiveRoute(tab) ? 'page' : undefined"
						>
							<slot :name="tab.name">
								<div class="flex items-center">
									{{ tab.name }}
								</div>
							</slot>
						</NuxtLink>
					</template>
				</nav>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import { useStaticAccountProfile } from '@/composables/useStaticAccountProfile'

const { isTabActive, setActiveTab, clearActiveTicketId } = useStaticAccountProfile()

const route = useRoute()
const router = useRouter()

interface Tab { // TODO: move to types file
	id: string
  name: string
  href: string
  route: string
  isDefault?: boolean
}

const props = defineProps({
	tabs: {
		type: Array as PropType<Tab[]>,
		required: true
	},
	staticPage: {
		type: Boolean,
		default: false
	}
})

const { tabs, staticPage } = toRefs(props)

const routeName = computed(() => route?.name?.toString())

const isActiveRoute = ({ isDefault, route }: Tab) => {
	return isDefault ? routeName.value === route : routeName.value?.includes(route)
}

const handleMobileNavigation = (payload: Event) => {
	if (staticPage.value) {
		const foundTab = tabs.value.find(t => t.href === (payload.target as HTMLSelectElement)?.value)
		if (!foundTab) { return }
		clearActiveTicketId() // REVIEW We clear this when clicking the main profile nav
		// to clear the active ticket so that we can see the full ticketing table
		setActiveTab(foundTab)
		return
	}
	const target = payload.target as HTMLSelectElement
	router.push(target?.value || '')
}

const handleStaticNavigation = (tab: Tab) => {
	clearActiveTicketId() // REVIEW We clear this when clicking the main profile nav
	// to clear the active ticket so that we can see the full ticketing table
	setActiveTab(tab)
}
</script>
